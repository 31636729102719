import React from "react";

function DisplayCertificate(props) {
  return (
    <>
      <div className="columns">
        <div className="column is-four-fifths is-offset-1">
          <article className="message is-success">
            <div className="message-body">
              <span>
                <strong>Certificate is valid.</strong>
                Compare below pdf for candidate details
              </span>
            </div>
          </article>
        </div>
      </div>
      <div className="columns">
        <div className="column is-four-fifths is-offset-1">
          <iframe src={props.pdf} width="100%" style={{ height: '500px' }}>
          </iframe>
        </div>
      </div>
    </>
  );
}

export default DisplayCertificate;
