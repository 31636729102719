import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import "./styles.scss";

function DashboardSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <div className="content">
          <ol type="1">
            <li><a href="https://www.freepik.com/free-photos-vectors/business">Business vector created by slidesgo - www.freepik.com</a></li>
            <li><a href="https://icons8.com">Icons8/Illustrations</a></li>
            <li><a href="https://www.drawkit.io/">DrawKit.io</a></li>
          </ol>
        </div>
      </div>
    </Section>
  );
}

export default DashboardSection;
