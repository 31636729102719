import React from "react";
import HeroSection from "./../../components/HeroSection";
import TestimonialsSection from "./../../components/TestimonialsSection";
import FeaturesSection from "./../../components/FeaturesSection";
import FeaturesSection2 from "./../../components/FeaturesSection2";
import NewsletterSection from "./../../components/NewsletterSection";
import ClientsSection from "./../../components/ClientsSection";
import PricingSection from "./../../components/PricingSection";
import { useRouter } from "./../../util/router.js";

import HeroImg from './hero.jpg'
import "./styles.scss";

function HomePage(props) {
  const router = useRouter();

  return (
    <>
      {/* <div className="attention-grabber has-background-primary has-text-black">
        Batch starts on November 1. Only 30 seats left. Hurry! <a href="https://docs.google.com/forms/d/e/1FAIpQLSe8VpwswZ956TSN9u4Y_MdO93YCflyYoufNsX24fvPMdI-niQ/viewform?usp=pp_url&entry.1350556480=Professional">Register Now!</a>.
      </div> */}
      <HeroSection
        color="white"
        size="medium"
        title="School of Practical skills"
        subtitle="Online classroom for learning practical skills that'll help you get a job. Learn web-development by building and deploying applications online."
        buttonText="Get Started"
        image={HeroImg}
        buttonOnClick={() => {
          router.push("/signup");
        }}
      />
      <TestimonialsSection
        color="light"
        size="medium"
        title="What people are saying"
        subtitle=""
      />
      <FeaturesSection
        color="white"
        size="medium"
        title="Why PractiSkills?"
        subtitle="We teach college students how to get a high paying job easily. PractiSkills teaches you web development by following a practical-first approach. If you are looking for a job, job change or a salary hike - you are at the right place."
      />
      <FeaturesSection2
        color="light"
        size="medium"
        title="What you'll learn?"
        subtitle="Practical skills that in software and web development. We start from the basiscs and end with an online portfolio website of your own."
        buttonText="Count me in"
        image="https://uploads.divjoy.com/undraw-chatting_2yvo.svg"
        buttonOnClick={() => {
          router.push("/pricing");
        }}
      />
      <ClientsSection color="white" size="normal" title="How it Works!" subtitle="" />
      <PricingSection color="light" size="medium" title="Pricing" subtitle="" />
      <NewsletterSection
        color="white"
        size="medium"
        title="Batch #2 Registrations open!"
        subtitle="Signup for a new account and enroll now!"
        buttonText="Get Started"
        inputPlaceholder="Enter your phone number"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default HomePage;
