import React, { useState } from "react";
import ContactForm from "./../ContactForm";
import contact from "./../../util/contact.js";
import "./styles.scss";

function Contact(props) {
  const [status, setStatus] = useState();

  const onSubmit = ({ name, email, message }) => {
    setStatus({ type: "pending" });

    contact.submit({ name, email, message }).then(() => {
      setStatus({
        type: "success",
        message: "Your message has been sent! We'll get back to you soon."
      });
    });
  };
  return (
    <center><p>Say Hi at <a href="mailto:hello@practiskills.com?Subject=Hello PractiSkills" target="_top">hello@practiskills.com</a></p></center>
  );
}

export default Contact;
