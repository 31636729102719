import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Features2 from "./../Features2";
import "./styles.scss";

function FeaturesSection2(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <div className="columns is-vcentered is-variable is-4 is-desktop">
          <div className="column is-12 is-2-desktop">
            <Features2
              align="right"
              items={[
                {
                  title: "Javascript",
                  description: "Learn the language that makes up the web.",
                  iconClass: "fab fa-js-square",
                  iconColor: "link"
                },
                {
                  title: "ReactJs",
                  description: "Learn to build high-performance web apps.",
                  iconClass: "fab fa-react",
                  iconColor: "info"
                },
                {
                  title: "Git & GitHub",
                  description: "Contributing to the open source.",
                  iconClass: "fab fa-github",
                  iconColor: "black"
                }
              ]}
            />
          </div>
          <div className="column">
            <figure className="FeaturesSection2__image image">
              <img src={props.image} alt="Illustration" />
            </figure>
          </div>
          <div className="column is-12 is-2-desktop">
            <Features2
              align="left"
              items={[
                {
                  title: "Cracking Js interview",
                  description: "Imagine a world where you get that job.",
                  iconClass: "fas fa-user-graduate",
                  iconColor: "link"
                },
                {
                  title: "Finding high paying jobs",
                  description: "Imagine a world where you need not look at the price tag before buying.",
                  iconClass: "fas fa-dollar-sign",
                  iconColor: "primary"
                },
                {
                  title: "Building online portfolio",
                  description: "Showcase your CV online. Make the companies want you more.",
                  iconClass: "fas fa-globe",
                  iconColor: "info"
                }
              ]}
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default FeaturesSection2;
