import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import SignIn from "./../SignIn";
import "./styles.scss";

function SignInSection(props) {

  // Go to page after signin
  const onSignin = (user) => {
    console.log('here', user)
  };

  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <SignIn
          buttonText={props.buttonText}
          parentColor={props.color}
          onSignin={onSignin}
        />
      </div>
    </Section>
  );
}

export default SignInSection;
