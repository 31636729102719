import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Faq from "./../Faq";
import "./styles.scss";

function FaqSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Faq
          items={[
            {
              question: "Why should I register to PractiSkills?",
              answer:
                "Practiskills follows practical-first approach. Meaning, you will learn by developing websites. And as the courses are held live - you get to ask questions and understand the subject like a real school."
            },
            {
              question: "How is PractiSkills different from other online courses?",
              answer:
                "PractiSkills is built with freshers in mind. We don't sell a course, instead teach you all the way from basics to getting a job. We teach you how to get a job too and also how to attend interviews as well."
            },
            {
              question: "How do I pay for the courses?",
              answer:
                "Sign up for a new account and Enroll to any course of your choice. There you'll receive further instructions to complete the payment."
            },
            {
              question: "I don't have money. Can you give it for free?",
              answer:
                "Even though we charge the bare minimum required for us to run smoothly, we also provide discounts and concessions to poor children. Please feel free to contact us in case you are in need."
            },
            {
              question: "How and when are the classes held?",
              answer:
                "You'll receive a private classroom link once you register and pay for the course. The private classes are conducted at 6PM - 7PM IST and the batch starts on March 1, 2020"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FaqSection;
