import React from "react";
import ContentSection from "./../../components/ContentSection";
import TeamBiosSection from "./../../components/TeamBiosSection";
import "./styles.scss";

function AboutPage(props) {
  return (
    <>
      <ContentSection
        color="primary"
        size="large"
        title="We help you get a job"
        subtitle="PractiSkills teaches the required skills for college students and computer enthusiasts to get a web development job. Our main goal is to emphasize Practical skills over theory-based education system. It is time to bring a revolution in the education system."
      />
      <TeamBiosSection
        color="white"
        size="medium"
        title="Meet the Team"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
