import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Testimonials from "./../Testimonials";
import "./styles.scss";

import prasad from './prasad.jpg'
import karthik from './karthik.jpg'

function TestimonialsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Testimonials
          items={[
            {
              avatar: "https://betterhalf.ai/static/media/alfred.dd8b1e69.jpg",
              name: "Alfred George",
              bio:
                "Practiskills helped me to get a very high paying job within 2 weeks of course completion. The practical-first approach in teaching helped me learn how to build apps on my own.",
              company: "UI Developer"
            },
            {
              avatar: prasad,
              name: "Prasad",
              role: "ReactJs Developer",
              bio:
                "My salary got hiked from 6lpa to 19lpa after learning negotiation skills and how to crack interviews from Practiskills. Freshers and experienced professionals - you must go for this course.",
              company: "ReactJs Developer"
            },
            {
              avatar: karthik,
              name: "Karthik Raikar",
              role: "Software Engineer",
              bio:
                "I completed the practiskills course and I got an 8lpa package job at my first attempt being a fresher.",
              company: "Software Engineer"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default TestimonialsSection;
