import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import TeamBios from "./../TeamBios";
import "./styles.scss";

import vijeth from './vijeth.jpg'
import sudesh from './sudesh.jpg'

function TeamBiosSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <TeamBios
          people={[
            {
              avatar: "https://betterhalf.ai/static/media/prasanna.e22d05b9.jpg",
              name: "Prasanna Mestha",
              role: "Founder",
              bio:
                "Hacker, Freelance developer, Open source contributor and a code lover. Enjoys experimenting new stuff and sharing knowledge with everyone.",
              twitterUrl: "https://twitter.com/prasannamestha?s=20",
              facebookUrl: "",
              linkedinUrl: "https://www.linkedin.com/in/prasannamestha/"
            },
            {
              avatar: vijeth,
              name: "Vijeth K Gowda",
              role: "Front-End-Developer intern",
              bio:
                "A React.js developer who writes code to make lives easy and beautiful. I am currently contributing to the development of practiskills.com.",
              twitterUrl: "https://twitter.com/vijethkgowda",
              facebookUrl: "",
              linkedinUrl: "https://www.linkedin.com/in/vijeth-k-476539152/"
            },{
              avatar: sudesh,
              name: "Sudesh Bhat",
              role: "Back-End-Developer intern",
              bio:
                "A NodeJs/ReactJs developer. I currently build REST api back-end for Practiskills.com. I love learning new languages.",
              linkedinUrl: "https://www.linkedin.com/in/sudesh-bhat-228944140"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default TeamBiosSection;
