import React, { useState } from "react";
import Auth from "./../Auth";
import "./styles.scss";

function SignUp(props) {
  const [status, setStatus] = useState();

  const onSubmit = ({phone, name, email, pass}) => {
    setStatus({ type: "pending" });
      fetch('https://api.practiskills.com/auth/signup', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          phone: phone,
          name: name,
          email: email,
          password: pass
        })
      })
        .then(resp => {
          if (!resp.ok) throw 'User already exist please sign in'
          return resp.json()
        })
        .then(resp => {
          window.location.href = `https://app.practiskills.com/?token=${resp.token}`
        })
        .catch(error => {
          setStatus({
            type: "error",
            message: error
          });
        })
  };

  return (
    <Auth
      mode="signup"
      buttonText={props.buttonText}
      parentColor={props.parentColor}
      onSubmit={onSubmit}
      status={status}
    />
  );
}

export default SignUp;
