import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Features from "./../Features";
import "./styles.scss";

import img1 from './gamer-colour-400px.png'
import img2 from './drawkit-content-man-colour-400px.png'
import img3 from './kingdom-4.png'
import img4 from './marginalia-payment-processed.png'

function FeaturesSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Features
          items={[
            {
              title: "Practical-first approach",
              description:
                "We prioritize practical skills over theory. That's why you'll be building apps from day 1 of the course and then learn the theory if required.",
              image: img2
            },
            {
              title: "Build your online presence",
              description:
                "Here, you not only learn javascript and reactJs, but also you'll be deploying the apps that you build online. By the end of the course you'll have your personal resume website online and a certification from PractiSkills.",
              image: img3
            },
            {
              title: "Learn tech-in-demand",
              description:
                "We don't teach you random stuff. The skills that you learn in PractiSkills are curated specially for getting a high paying job.",
              image: img4
            },
            {
              title: "100% Online & Interactive",
              description:
                "Learn from anywhere in the world. We have live sessions from 6PM - 8PM IST on every alternating days. The first batch starts on March 1, 2020.",
              image: img1
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FeaturesSection;
