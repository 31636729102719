import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Clients from "./../Clients";
import "./styles.scss";

import img1 from './drawkit-folder-man-colour-400px.png'
import img2 from './app-user-colour-400px.png'
import img3 from './drawkit-content-man-colour-400px.png'

function ClientsSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Clients
          items={[
            {
              name: "Instagram",
              image: img1,
              width: "150px",
              text: 'Signup for a new account',
            },
            {
              name: "Slack",
              image: img2,
              width: "150px",
              text: 'Enroll to any course of your choice',
            },
            {
              name: "Tinder",
              image: img3,
              width: "150px",
              text: 'You get access to Private Classroom',
            },
          ]}
        />
      </div>
    </Section>
  );
}

export default ClientsSection;
