import React, { useState } from "react"
import Auth from "./../Auth"
import "./styles.scss"
import { Link } from 'react-router-dom'

function ChangePass(props) {
  const [status, setStatus] = useState();

  const onSubmit = ({ pass }) => {
    let token = new URL(window.location.href).searchParams.get('token')
    setStatus({ type: "pending" });
    fetch('https://api.practiskills.com/auth/reset-password', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        newPassword: pass,
        token: token
      })
    })
      .then(resp => {
        if (!resp.ok) throw new Error('Timeout')
        return resp.json()
      })
      .then(resp => {
        setStatus({
        type: "success",
        message: (<p>Password has been reset. <Link to="/signin">Click Here</Link> to login</p>)
      });
  
      }).catch(error => {
        setStatus({
          type: "error",
          message: (<p>Could not reset password. <Link to="/forgotpass">Click Here</Link> to try again.</p>)
        });
      })
  };

  return (
    <Auth
      mode="changepass"
      buttonText={props.buttonText}
      parentColor={props.parentColor}
      onSubmit={onSubmit}
      status={status}
    />
  );
}

export default ChangePass;
