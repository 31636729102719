import React, { useState, useEffect } from 'react'
import DisplayCertificate from "../../components/certificate"
import SandeepNamdev from './SandeepNamdev.pdf'
import Meghana from './Meghana.pdf'
import LokeshMR from './LokeshMR.pdf'
import Ashish from './AshishShenoyH.pdf'

function Certificate() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(false);
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    let url = window.location.pathname
    let cid = url.replace('/certificate/', '')
    if (cid === 'PB01U089') {
      setLoading(false)
      setStatus(true)
      setPdf(SandeepNamdev)
    } 
    else if (cid === 'PB01U090') {
      setLoading(false)
      setStatus(true)
      setPdf(Meghana)
    } 
    else if (cid === 'PB01U091') {
      setLoading(false)
      setStatus(true)
      setPdf(LokeshMR)
    }
    else if (cid === 'PB01U092') {
      setLoading(false)
      setStatus(true)
      setPdf(Ashish)
    } 
    else {
      fetch('https://api.practiskills.com/certificate', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        }, body: JSON.stringify({
          CIN: cid,
        })
      })
        .then(resp => {
          if (!resp.ok) throw new Error('')
          setStatus(true)
          setLoading(false)
          return resp.json()
        })
        .then(resp => {
          setPdf(resp.PDF)
        })
        .catch(error => {
          setLoading(false)
        })
    }
  }, [])

  if (loading) {
    return (
      <>
        <div className="columns">
          <div className="column is-four-fifths is-offset-1">
            <article className="message is-warning">
              <div className="message-body">
                <strong>Fetching your certificate please wait</strong>
              </div>
            </article>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      {status ? <DisplayCertificate pdf={pdf} /> : <div className="columns">
        <div className="column is-four-fifths is-offset-1">
          <article className="message is-danger">
            <div className="message-body">
              Please check the <strong>URL</strong> correctly or this Certificate is not issued by the practiskills.com and if there is any queries please contact us  on <strong><a href="mailto:hello@practiskills.com?Subject=Hello PractiSkills" target="_top">hello@practiskills.com</a></strong>
            </div>
          </article>
        </div>
      </div>}
    </>
  );
}

export default Certificate;