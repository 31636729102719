import React from "react";
import "./styles.scss";

import { useRouter } from "./../../util/router.js";

function Pricing(props) {
  const router = useRouter();
  return (
    <div className="columns is-centered is-variable is-5">
      {props.items.map((item, index) => (
        <div className="Pricing__column column" key={index}>
          <div
            className={
              "Pricing__card card" +
              (item.emphasized === true ? " emphasized" : "")
            }
          >
            <div className="Pricing__card-content card-content">
              <div className="Pricing__period has-text-weight-bold">
                {item.timespan}
              </div>
              <div className="Pricing__price has-text-weight-bold">
                <span className="Pricing__price-symbol is-size-3">₹</span>
                <span className="is-size-1">{item.price}</span>
                <span className="Pricing__price-month is-size-4"></span>
              </div>
              <p className="Pricing__description">
                {item.description}
              </p>
              <table className="table has-background-light is-bordered Pricing__button">
                <tbody>
                  {item.items.map(feat => (<tr key={feat}><td><i className="fas fa-check has-text-primary"></i> {feat}</td></tr>))}
                </tbody>
              </table>
              {item.disabled ? (<button className="Pricing__button button is-medium is-primary" disabled>Registration Full</button>) : (
              <button 
                className="Pricing__button button is-medium is-primary"
                onClick={() => {
                  router.push("/signup");
                }}
              >
                {props.buttonText}
              </button>
              )}
              
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Pricing;
