import React, { useState } from "react";
import Auth from "./../Auth";
import "./styles.scss";

function SignIn(props) {
  const [status, setStatus] = useState();

  const onSubmit = ({ email, pass }) => {
    setStatus({ type: "pending" });
    fetch('https://api.practiskills.com/auth/login', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        password: pass
      })
    })
      .then(resp => {
        if (!resp.ok) throw new Error('Wrong username/password')
        return resp.json()
      })
      .then(resp => {
        window.location.href =  `https://app.practiskills.com/?token=${resp.token}`
      }).catch(error => {
        setStatus({
          type: "error",
          message: error.message
        });
      })
  };

  return (
    <Auth
      mode="signin"
      buttonText={props.buttonText}
      parentColor={props.parentColor}
      onSubmit={onSubmit}
      status={status}
    />
  );
}

export default SignIn;
