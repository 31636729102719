import React from "react";
import PricingSection from "./../../components/PricingSection";
import NewsletterSection from "./../../components/NewsletterSection";
import "./styles.scss";

function PricingPage(props) {
  return (
    <>
      <NewsletterSection
        color="white"
        size="medium"
        title="Registrations Full!"
        subtitle="Stay tuned for the next batch!"
        buttonText="Get Started"
        inputPlaceholder="Enter your phone number"
        subscribedMessage="You are now subscribed!"
      />
      <PricingSection color="light" size="medium" title="Pricing" subtitle="" />
    </>
  );
}

export default PricingPage;
