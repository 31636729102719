import React, { useEffect } from "react";
import DashboardSection from "./../../components/DashboardSection";
import { useAuth } from "./../../util/auth.js";
import { useRouter } from "./../../util/router.js";
import "./styles.scss";

function DashboardPage(props) {
  const auth = useAuth();
  const router = useRouter();

  // Redirect to signin
  // if not signed in.

  return (
    <DashboardSection
      color="white"
      size="large"
      title="Credits"
      subtitle="Thanks to all the developers/designers of the following for helping us build this website."
    />
  );
}

export default DashboardPage;
