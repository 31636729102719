import React, { useState } from "react"
import Auth from "./../Auth"

function ForgotPass(props) {
  const [status, setStatus] = useState();

  const onSubmit = ({ email }) => {
    setStatus({ type: "pending" });
    
    fetch('https://api.practiskills.com/auth/forgot', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        email: email
      })
    })
      .then(resp => {
        if (!resp.ok) throw 'Wrong username'
        return resp.json()
      })
      .then(resp => {
        setStatus({
        type: "success",
        message: "Password reset email sent. Check you inbox"
      });
  
      }).catch(error => {
        setStatus({
          type: "error",
          message: error
        });
      })

  };

  return (
    <Auth
      mode="forgotpass"
      buttonText={props.buttonText}
      parentColor={props.parentColor}
      onSubmit={onSubmit}
      status={status}
    />
  );
}

export default ForgotPass;
