import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import Pricing from "./../Pricing";
import "./styles.scss";

function PricingSection(props) {
  return (
    <Section color={props.color} size={props.size} id="pricing">
      <div className="PricingSection__container container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Pricing
          buttonText="Register"
          onChoosePlan={planId => {
            // Add your own payments logic here
            alert(`You chose the plan "${planId}"`);
          }}
          items={[
            {
              id: "starter",
              timespan: "Starter",
              price: "499",
              description:
                "Learn the basics of creating a website in ReactJs",
              link: '/signup',
              items: [
                'Learn Javascript',
                'Learn ReactJs',
                'Build your own portfolio website'
              ],
              disabled: false,
            },
            {
              id: "professional",
              timespan: "Professional",
              price: "1999",
              description:
                "Learn how professionals develop apps and how to deploy your website online.",
              link: '/signup',
              items: [
                'Starter plan included',
                'Learn git',
                'Contribute to github',
                'Register and deploy a website online',
              ],
              disabled: false,
            },
            {
              id: "expert",
              timespan: "Job magnet",
              price: "5999",
              description:
                "Learn how to find high-paying jobs, crack interviews and negotiate for high salaries.",
              link: '/signup',
              items: [
                'Cracking javascript interview',
                'Cracking ReactJs interview',
                'How to write a good resume',
                'Learn how to find high-paying jobs',
                'Salary negotiation tricks & tips'
              ],
              disabled: false,
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default PricingSection;
